import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsService, UserService } from '@app/core/services';
import { HowdenLogService, HowdenSecurityService } from '@howdeniberia/core-front';

@Component({
  selector: 'howden-granted',
  templateUrl: './granted.component.html',
  styleUrls: ['./granted.component.scss']
})
export class GrantedComponent implements OnInit {
  constructor(
    private router: Router,
    private userSrv: UserService,
    private actionSrv: ActionsService,
    private howdenSecuritySrv: HowdenSecurityService,
    private logSrv: HowdenLogService
  ) {
  }

  ngOnInit(): void {
    // should ask for Id Token
    this.getToken();
  }

  getToken() {
    if (this.userSrv.actualDataValidAD()) {
      this.howdenSecuritySrv.GetIdentityToken().subscribe({
        next: (res) => {
          if (res.accessApps?.length === 0 && !res.isSuperAdmin) {
            this.logSrv.Log('token obtenido pero sin acceso a ninguna App', res);
            this.actionSrv.hideLoading();
            this.router.navigateByUrl('forbidden');
          } else {
            this.userSrv.saveIdentificationResult(res);
            this.router.navigateByUrl('preferences');
          }
        },
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        error: (err: any) => {
          this.logSrv.Log('GrantedComponent - Error obteniendo token', err);
          this.actionSrv.hideLoading();
          this.router.navigateByUrl('forbidden');
        }
      });
    }
  }
}

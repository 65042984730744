import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PasswordValidators } from '@app/shared/validators';
import { HowdenAlertService, HowdenSecurityService } from '@howdeniberia/core-front';
import { passwordMatchValidator } from './password-match.validator';

@Component({
  selector: 'howden-reset-pass',
  templateUrl: './reset-pass.component.html'
})
export class ResetPassComponent implements OnInit {
  readonly C_PASSWORD_NEW = 'password_new';
  readonly C_PASSWORD_REPEAT = 'password_repeat';

  error = false;

  form: FormGroup = new FormGroup({
    [this.C_PASSWORD_NEW]: new FormControl('', [Validators.required, PasswordValidators.format]),
    [this.C_PASSWORD_REPEAT]: new FormControl('', [Validators.required, PasswordValidators.format])
  }, { validators: [passwordMatchValidator] });

  private token = '';
  private email = '';
  private app = '';
  private callback = '';

  constructor(
    private route: ActivatedRoute,
    private alertSrv: HowdenAlertService,
    private apiSecuritySrv: HowdenSecurityService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params['token'] && params['email']) {
        this.token = encodeURIComponent(params['token']);
        this.email = encodeURIComponent(params['email']);
        if (params['app']) {
          this.app = encodeURIComponent(params['app']);
        }
        if(params['callback']){
          this.callback = encodeURIComponent(params['callback']);
        }
      } else {
        this.error = true;
        this.alertSrv.error(
          $localize`:@@front-login-reset-pass.title-error: Aviso de error`,
          $localize`:@@front-login.reset-pass.no-token: la página no ha recibido token, no es posible solicitar una nueva contraseña`,
          true
        );
      }
    });
  }

  updatePassword() {
    if (this.form.get(this.C_PASSWORD_NEW)?.value !== this.form.get(this.C_PASSWORD_REPEAT)?.value) {
      this.alertSrv.error(
        $localize`:@@front-login.reset-pass.title-error: Aviso de error`,
        $localize`:@@front-login.reset-pass.missmatch-password: Las contraseñas no coinciden`,
        true
      );
      return;
    }

    this.apiSecuritySrv.ResetPassword(
      this.form.get(this.C_PASSWORD_NEW)?.value,
      this.form.get(this.C_PASSWORD_REPEAT)?.value,
      this.token,
      this.email,
      this.callback
    ).subscribe({
      next: () => {
        this.router.navigate(['/external-login'], { queryParams: { title: this.app,  callback: this.callback } });
      }
    });

    return;
  }
}

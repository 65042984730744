import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ILoaderData } from '@app/shared/components/loader/loader-data';
import { LoaderComponent } from '@sharedComponents/loader/loader.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  private _loading = false;

  dialogRef: MatDialogRef<LoaderComponent> | undefined;
  loginChanged: Subject<boolean> = new Subject();

  constructor(private dialog: MatDialog) {
  }

  changeLoading(msg: string | null | undefined = null): void {
    this._loading = !this._loading;
    this.loginChanged.next(this._loading);

    msg = msg ?? $localize`:@@front-login.actions-service.loading-message: Cargando ...`;

    if (this._loading) {
      const config = {
        disableClose: true,
        hasBackdrop: true,
        data: { msg: msg } as ILoaderData
      } as MatDialogConfig;
      this.dialogRef = this.dialog.open(LoaderComponent, config);
    } else {
      this.dialogRef?.close();
    }
  }

  hideLoading(): void {
    this.dialogRef?.close();
  }

  get isLoading(): boolean {
    return this._loading;
  }
}

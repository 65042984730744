import { Injectable } from '@angular/core';
import { HowdenInformation } from '@app/core/helpers';
import { IAppData } from '@app/core/models';
import { environment } from '@environment/environment';
import { EnviromentZone, HowdenLogService, IAuthData } from '@howdeniberia/core-front';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {
  private keyApp = `${new HowdenInformation().NameNormalized}`;
  private keyAuthentication = `hw-auth`;

  storage: Storage;

  constructor(private cookieSrv: CookieService, private logSrv: HowdenLogService) {
    this.storage = localStorage;
  }

  readAppData(): IAppData | null {
    return this.genReadData<IAppData>(this.keyApp, false);
  }

  saveAppData(data: IAppData): void {
    const dataStr = JSON.stringify(data);
    this.storage.setItem(this.keyApp, dataStr);
  }

  readAuthData(): IAuthData | null {
    return this.genReadData<IAuthData>(this.keyAuthentication, true);
  }

  saveAuthData(data: IAuthData): void {
    const toSave = { ...data };
    if (toSave.userPreferences?.secondaryLogo) {
      toSave.userPreferences.secondaryLogo = '';
    }
    const dataStr = this.authDataToStrCheckSize(toSave);
    const expires = new Date();

    expires.setDate(expires.getDate() + 1);

    this.logSrv.Info(`ENVIRONMENT ZONE===>: ${environment.zone}`, dataStr);

    const domApp = this.domain;

    this.logSrv.Info(`try to save cookie dom 1: ${domApp} with key: ${this.keyAuthentication}`, dataStr);

    this.cookieSrv.set(this.keyAuthentication, dataStr, expires, '/', domApp, true, 'Lax');
  }

  clearLocalStorage(): void {
    this.storage.clear();
  }

  clearCookies(): void {
    this.cookieSrv.delete(this.keyAuthentication, '/', this.domain);
  }

  get domain(): string {
    let domainEnding = '-wrong';

    if (environment.zone === EnviromentZone.PRODUCTION
      || environment.zone === EnviromentZone.PROHOWDENGROUP
      || environment.zone === EnviromentZone.PROHOWDENARTAI) {
      domainEnding = '';
    } else if (environment.zone === EnviromentZone.LOCAL
      || environment.zone === EnviromentZone.DEVELOP
      || environment.zone === EnviromentZone.DEVHOWDENGROUP
      || environment.zone === EnviromentZone.DEVHOWDENARTAI) {
      domainEnding = '-dev';
    } else if (environment.zone === EnviromentZone.STAGING
      || environment.zone === EnviromentZone.PREHOWDENGROUP
      || environment.zone === EnviromentZone.PREHOWDENARTAI) {
      domainEnding = '-staging';
    }

    if (environment.zone === EnviromentZone.DEVHOWDENARTAI
      || environment.zone === EnviromentZone.PREHOWDENARTAI
      || environment.zone === EnviromentZone.PROHOWDENARTAI) {
      return `.app${domainEnding}.howdenartai.com`;
    }

    return `.app${domainEnding}.howdeniberia.com`;
  }

  private authDataToStrCheckSize(data: IAuthData): string {
    const objCopy: IAuthData = JSON.parse(JSON.stringify(data));
    let dataStr = JSON.stringify(objCopy);
    const byts = new TextEncoder().encode(dataStr).byteLength;
    if (byts > 3096) {
      // clear AD data
      objCopy.tokenAD = undefined;
      objCopy.tokenADValidDate = null;
      objCopy.id = undefined;
      dataStr = JSON.stringify(objCopy);
      dataStr = dataStr.replace(',"tokenADValidDate":null', '');
    }
    return dataStr;
  }

  private genReadData<T>(key: string, fromCookie: boolean): T | null {
    const val = fromCookie ? this.cookieSrv.get(key) : this.storage.getItem(key);
    if (!val) {
      return null;
    }
    const obj = JSON.parse(val);
    return <T>obj;
  }
}

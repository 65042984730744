import { GENERAL_CONSTANTS, HowdenApiEnviroment, IHowdenApiInfo, IHowdenApiPaths } from '@howdeniberia/core-front';

export const API_Config: IHowdenApiInfo[] = [
  {
    name: GENERAL_CONSTANTS.ApiSecurityName,
    paths: {
      local: 'https://localhost:7128',
      develop: 'https://how-apisecurity.app-dev.howdeniberia.com',
      staging: 'https://how-apisecurity.app-staging.howdeniberia.com',
      production: 'https://how-apisecurity.app.howdeniberia.com',
      devHowdenGroup: 'https://how-apisecurity.app-dev.howdeniberia.com',
      preHowdenGroup: 'https://how-apisecurity.app-staging.howdeniberia.com',
      proHowdenGroup: 'https://how-apisecurity.app.howdeniberia.com',
      devHowdenArtai: 'https://how-ar-apisecurity-dev.azurewebsites.net',
      preHowdenArtai: 'https://how-ar-apisecurity-pre.azurewebsites.net',
      proHowdenArtai: 'https://how-ar-apisecurity-pro.azurewebsites.net'
    } as IHowdenApiPaths,
    needsToken: true,
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE
  }
] as IHowdenApiInfo[];

import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HowdenInformation } from '@app/core/helpers';
import { IAppData } from '@app/core/models';
import { ActionsService, UserService } from '@app/core/services';
import { environment } from '@environment/environment';
import {
  DialogResultTypes,
  EnviromentZone,
  HowdenAlertService,
  IAuthData,
  IDialogResult,
  IHowdenYesNoData,
  YesNoService
} from '@howdeniberia/core-front';

@Component({
  selector: 'howden-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  urlBack = new FormControl('', [Validators.required]);
  authData: IAuthData | undefined = undefined;
  appData: IAppData | undefined = undefined;
  finished = false;
  textSize = 0;
  textSizeUrl = 0;

  constructor(
    private router: Router,
    private clipBoard: Clipboard,
    private userSrv: UserService,
    private actionsSrv: ActionsService,
    private alertSrv: HowdenAlertService,
    private info: HowdenInformation,
    private readonly yesNoSrv: YesNoService
  ) {
  }

  ngOnInit(): void {
    this.authData = this.userSrv.authData;
    this.appData = this.userSrv.appData;

    const callBackStr = this.userSrv.callBackStr;

    if (this.userSrv.validCallBack) {
      if (environment.zone === EnviromentZone.LOCAL) {
        this.urlBack.setValue(callBackStr);
        this.finishProcess();
      } else {
        if (this.appData.goDirect) {
          // clear the local storage
          this.userSrv.clearLocalData();
          window.location.href = callBackStr;
        } else {
          this.router.navigateByUrl('sucess');
        }
      }
    } else {
      this.finishProcess();
    }
  }

  get envName(): string {
    return `${this.info.Version} ${this.info.Enviroment}`;
  }

  finishProcess(): void {
    this.finished = true;
    this.actionsSrv.hideLoading();
  }

  navigate() {
    if (!this.urlBack.valid) {
      return;
    }

    const url = this.urlBack.value ? this.urlBack.value : '';
    document.location.href = url;
  }

  copy(text: string | undefined): void {
    if (!text) {
      return;
    }

    this.clipBoard.copy(text);
    this.alertSrv.info('successfully copied data', '');
  }

  copyUsrVar(): void {
    const str = JSON.stringify(this.authData);

    this.clipBoard.copy(str);
    this.alertSrv.info('successfully copied data', '');
  }

  clean(): void {
    const callBack = this.userSrv.appData.callback ? `?callback=${this.userSrv.appData.callback}` : '';

    this.userSrv.logout();

    const dest = callBack === '' ? 'login' : `login-dir${callBack}`;
    const options = {
      title: $localize`:@@front-login.result.clean-dialog.title: Atención`,
      subTitle: '',
      question: $localize`:@@front-login.result.clean-dialog.question: navegar a ${dest} ?`,
      icon: 'warning',
      disableClose: true,
      widthPixels: 450
    } as IHowdenYesNoData;

    this.yesNoSrv.open(options).subscribe((result: IDialogResult<boolean>) => {
      if (result.result === DialogResultTypes.Yes) {
        this.router.navigateByUrl(dest);
      } else {
        this.alertSrv.success('cookie borrada con éxito!', '');
      }
    });
  }

  size(): void {
    const val = this.urlBack.value ? this.urlBack.value : '';
    const valJson = JSON.stringify(val);
    this.textSize = new TextEncoder().encode(valJson).byteLength;
    this.textSizeUrl = new TextEncoder().encode(encodeURI(valJson)).byteLength;
  }
}

import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { HowdenLogService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private logSrv: HowdenLogService
  ) {
  }

  checkAndSetActiveAccount(): boolean {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      this.authService.instance.getAllAccounts();
      return true;
    }

    return false;
  }

  LoginMicrosoft(): Observable<boolean> {
    return new Observable(observer => {
      // handle auth redired/do all initial setup for msal
      this.authService.handleRedirectObservable().subscribe({
        next: () => {
          // Check if user signed in
          const account = this.authService.instance.getActiveAccount();

          if (!account) {
            // redirect anonymous user to login page
            this.authService.loginRedirect({
              ...this.msalGuardConfig.authRequest
            } as RedirectRequest);
            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        },
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        error: (err: any) => {
          this.logSrv.Error('error login ms', err);
          observer.next(false);
          observer.complete();
        }
      });
    }
    );
  }

  GetTokenSilent(): Observable<AuthenticationResult> {
    return this.authService.acquireTokenSilent(
      {
        ...this.msalGuardConfig.authRequest
      } as SilentRequest
    );
  }

  GetToken(): Observable<void> {
    return this.authService.acquireTokenRedirect(
      {
        ...this.msalGuardConfig.authRequest
      } as SilentRequest
    );
  }

  AnyADActiveAccount(): boolean {
    const account = this.authService.instance.getActiveAccount();
    return !!account;
  }
}

<div class="main-external flex f-fd--column-md f-gap--25px f-jc--center f-ai--center">
  <div class="login-panel mat-elevation-z24">
    <img src="assets/images/logo-howden.png" class="howden-logo" alt="Howden Logo" />

    <form [formGroup]="form" (ngSubmit)="updatePassword()">
      <h2 class="login-title" i18n="@@front-login.reset-pass.title">
        Solicitud de nueva contraseña
      </h2>

      <div class="field">
        <label class="label" i18n="@@front-login.reset-pass.new-password">Contraseña nueva</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_PASSWORD_NEW"
            i18n-placeholder="@@front-login.reset-pass.new-pass-placeholder"
            placeholder="Introduzca su contraseña nueva"
            type="password"
          />
          @if (form.get(C_PASSWORD_NEW)?.hasError('required')) {
            <mat-error i18n="@@front-login.reset-pass.new-password-required">
              El campo contraseña nueva es obligatorio
            </mat-error>
          }
          @if (form.get(C_PASSWORD_NEW)?.hasError('format')) {
            <mat-error>
              {{ form.get(C_PASSWORD_NEW)?.getError('format').message }}
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="field margin-top-20">
        <label class="label" i18n="@@front-login.reset-pass.repeat-new-password">Repetir contraseña nueva</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_PASSWORD_REPEAT"
            i18n-placeholder="@@front-login.reset-pass.repeat-pass-placeholder"
            placeholder="Introduzca de nuevo su contraseña nueva"
            type="password"
          />
          @if (form.get(C_PASSWORD_REPEAT)?.hasError('required')) {
            <mat-error i18n="@@front-login.reset-pass.repeat-password-required">
              Es necesario repetir la contraseña nueva
            </mat-error>
          }
          @if (form.get(C_PASSWORD_REPEAT)?.hasError('format')) {
            <mat-error>
              {{ form.get(C_PASSWORD_REPEAT)?.getError('format').message }}
            </mat-error>
          }
        </mat-form-field>

        @if (form.invalid && form.get(C_PASSWORD_REPEAT)?.valid && form.get(C_PASSWORD_NEW)?.valid) {
          <mat-error i18n="@@front-login.reset-pass.repeat-password-mismatch">
            Las contraseñas no coinciden
          </mat-error>
        }
      </div>

      <div>
        <button mat-raised-button [disabled]="form.invalid || error" color="primary" i18n="@@front-login.reset-pass.ok">
          Aceptar
        </button>
      </div>
    </form>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, UserService } from '@app/core/services';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { HowdenLogService } from '@howdeniberia/core-front';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'howden-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {
  constructor(
    private route: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userSrv: UserService,
    private logingSrv: LoginService,
    private logSrv: HowdenLogService
  ) {
  }

  ngOnInit(): void {
    if (!this.userSrv.actualDataValidAD()) {
      this.loginMicrosoft();
    } else {
      this.route.navigateByUrl('/granted');
    }
  }

  loginMicrosoft(): void {
    if (this.logingSrv.AnyADActiveAccount()) {
      this.waitFormMSToken().subscribe(res => {
        if (!res) {
          this.waitForMSLog();
        }
      });
    } else {
      this.waitForMSLog();
    }
  }

  private waitFormMSToken(): Observable<boolean> {
    return new Observable(observer => {
      this.logingSrv.GetTokenSilent().pipe(
        tap({
          next: (payload) => {
            this.logSrv.Info(`msal get token sucess`, payload.accessToken);
            this.processAuthentication(payload);
            observer.next(true);
            observer.complete();
          },
          error: (err: unknown) => {
            this.logSrv.Error(`msal get token error `, err);
            this.logingSrv.GetToken().subscribe({
              next: () => {
                observer.next(false);
                observer.complete();
              },
              error: () => this.logSrv.Error(`msal get token error `, err)
            });
          }
        })
      ).subscribe();
    });
  }

  private waitForMSLog(): void {
    this.msalBroadcastService.msalSubject$.pipe().subscribe({
      next: (result: EventMessage) => {
        if (result.eventType === EventType.LOGIN_SUCCESS
          || result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || result.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS) {
          this.logSrv.Info(`msalBroadcast sucess ${result.eventType.toString()}`, (result.payload as AuthenticationResult).accessToken);
        }
        const payload = result.payload as AuthenticationResult;
        this.processAuthentication(payload);
      },
      error: (err: unknown) => this.logSrv.Error('msalBroadcast failed', err)
    });

    this.logingSrv.LoginMicrosoft().subscribe();
  }

  private processAuthentication(payload: AuthenticationResult): void {
    if (payload?.account) {
      this.authService.instance.setActiveAccount(payload?.account);
      this.userSrv.saveAuthenticationResult(payload);
      this.route.navigateByUrl('/granted');
    }
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class PasswordValidators {
  static format: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if ((!control.value || control.value.length === 0) && control.hasValidator(Validators.required)) {
      return null;
    }

    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const hasSymbols = /[_\W0-9]/.test(control.value);
    const hasLength = control.value.length >= 8;
    const valid = hasNumber && hasUpper && hasLower && hasSymbols && hasLength;

    return !valid ? {
      format: {
        message: $localize`:@@front-login.passwords-validators.format: La contraseña debe tener 8 caracteres mínimo y contener mayúsculas, minúsculas, dígitos y símbolos`
      }
    } : null;
  };
}

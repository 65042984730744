{
  "name": "howden-login",
  "version": "v3.18.2",
  "title": "Howden Login",
  "languages": [
    "es",
    "en"
  ],
  "copyright": "Copyright 2015-2024 | Howden Iberia | Todos los derechos reservados",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4300",
    "start-dev": "ng serve --port 4300 -c develop",
    "start-pre": "ng serve --port 4300 -c staging",
    "start-pro": "ng serve --port 4300 -c production",
    "start-DevelopmentHowdenArtai": "ng serve --port 4300 -c devHowdenArtai",
    "start-StagingHowdenArtai": "ng serve --port 4300 -c preHowdenArtai",
    "start-ProductionHowdenArtai": "ng serve --port 4300 -c proHowdenArtai",
    "start-DevelopmentHowdenGroup": "ng serve --port 4300 -c devHowdenGroup",
    "start-StagingHowdenGroup": "ng serve --port 4300 -c preHowdenGroup",
    "start-ProductionHowdenGroup": "ng serve --port 4300 -c proHowdenGroup",
    "build": "ng build",
    "build-dev": "ng build -c develop",
    "build-pre": "ng build -c staging",
    "build-pro": "ng build -c production",
    "build-DevelopmentHowdenArtai": "ng build --configuration devHowdenArtai",
    "build-StagingHowdenArtai": "ng build --configuration preHowdenArtai",
    "build-ProductionHowdenArtai": "ng build --configuration proHowdenArtai",
    "build-DevelopmentHowdenGroup": "ng build --configuration devHowdenGroup",
    "build-StagingHowdenGroup": "ng build --configuration preHowdenGroup",
    "build-ProductionHowdenGroup": "ng build --configuration proHowdenGroup",
    "watch": "ng build --watch",
    "watch-dev": "ng build --watch --configuration develop",
    "watch-pre": "ng build --watch --configuration staging",
    "watch-pro": "ng build --watch --configuration production",
    "watch-DevelopmentHowdenArtai": "ng build --watch --configuration devHowdenArtai",
    "watch-StagingHowdenArtai": "ng build --watch --configuration preHowdenArtai",
    "watch-ProductionHowdenArtai": "ng build --watch --configuration proHowdenArtai",
    "watch-DevelopmentHowdenGroup": "ng build --watch --configuration devHowdenGroup",
    "watch-StagingHowdenGroup": "ng build --watch --configuration preHowdenGroup",
    "watch-ProductionHowdenGroup": "ng build --watch --configuration proHowdenGroup",
    "test": "ng test",
    "lint": "eslint . --ext .ts",
    "lint.fix": "eslint . --ext .ts —-fix",
    "i18n-extract": "ng extract-i18n --output-path=src/locale --progress=true"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.1",
    "@angular/cdk": "^18.2.1",
    "@angular/common": "^18.2.1",
    "@angular/compiler": "^18.2.1",
    "@angular/core": "^18.2.1",
    "@angular/forms": "^18.2.1",
    "@angular/material": "^18.2.1",
    "@angular/platform-browser": "^18.2.1",
    "@angular/platform-browser-dynamic": "^18.2.1",
    "@angular/router": "^18.2.1",
    "@azure/msal-angular": "^3.0.21",
    "@azure/msal-browser": "^3.18.0",
    "@howdeniberia/core-front": "^1.18.3",
    "moment": "^2.30.1",
    "ngx-cookie-service": "^18.0.0",
    "ngx-currency": "^18.0.0",
    "ngx-toastr": "~18.0.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular-eslint/builder": "^18.2.1",
    "@angular-eslint/eslint-plugin": "^18.2.1",
    "@angular-eslint/eslint-plugin-template": "^18.2.1",
    "@angular-eslint/schematics": "^18.2.1",
    "@angular-eslint/template-parser": "^18.2.1",
    "@angular/cli": "^18.2.1",
    "@angular/compiler-cli": "^18.2.1",
    "@angular/localize": "^18.2.1",
    "@schematics/angular": "18.2.1",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^20.14.10",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "@typescript-eslint/utils": "^7.11.0",
    "eslint": "^8.57.0",
    "eslint-plugin-rxjs": "^5.0.3",
    "jasmine-core": "^5.1.2",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "typescript": "~5.4.5"
  }
}
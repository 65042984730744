import { Component, OnInit } from '@angular/core';
import { ActionsService, ErrorService, UserService } from '@app/core/services';

@Component({
  selector: 'howden-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  msg: string | undefined;
  showGoHome = false;

  constructor(
    private errSrv: ErrorService,
    private userSrv: UserService,
    private actionsSrv: ActionsService
  ) {
  }

  ngOnInit(): void {
    this.msg = this.errSrv.ErrorMsg;
    this.showGoHome = !this.userSrv.validCallBack;
    this.actionsSrv.hideLoading();
  }

  goHome() {
    window.location.href = this.userSrv.callBackStr;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsService, UserService } from '@app/core/services';
import {
  HowdenAlertService,
  HowdenAskForDataService,
  HowdenLogService,
  HowdenSecurityService,
  IHowdenAskForData
} from '@howdeniberia/core-front';
import { tap } from 'rxjs';

@Component({
  selector: 'howden-login-external',
  templateUrl: './login-external.component.html',
  styleUrls: ['./login-external.component.scss']
})
export class LoginExternalComponent implements OnInit {
  private DEFAULT_TITLE = 'Howden Iberia';

  readonly C_USERNAME = 'userName';
  readonly C_PASSWORD = 'password';

  title = '';
  callback = '';
  loginError = false;

  formLogin: FormGroup = new FormGroup({
    [this.C_USERNAME]: new FormControl('', [Validators.required]),
    [this.C_PASSWORD]: new FormControl('', [Validators.required])
  });

  constructor(
    private route: ActivatedRoute,
    private apiSecuritySrv: HowdenSecurityService,
    private router: Router,
    private userSrv: UserService,
    private actionSrv: ActionsService,
    private logSrv: HowdenLogService,
    private askForDataSrv: HowdenAskForDataService,
    private alertSrv: HowdenAlertService
  ) {
  }

  ngOnInit(): void {
    this.userSrv.logout();

    this.route.queryParams.subscribe((params) => {
      if (params['app']) {
        this.title = decodeURIComponent(params['app']);
      } else {
        this.title = this.DEFAULT_TITLE;
      }

      if (params['callback']) {
        this.callback = decodeURIComponent(params['callback']);
      }
      this.userSrv.setGoDirect(true);
      this.userSrv.setCallBack(this.callback);
      this.userSrv.setExternalLogin();
    });

    if (this.userSrv.actualDataValidID()) {
      this.navigate();
    }
  }

  login() {
    if (!this.formLogin.invalid) {
      this.actionSrv.changeLoading();

      this.apiSecuritySrv.LoginIdentity(this.userName, this.passWord).subscribe({
        next: (res) => {
          this.loginError = false;
          this.logSrv.Info('saving cookie');
          this.userSrv.saveIdentificationResult(res);
          this.userSrv.saveUserInfo(res);
          this.navigate();
        },
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        error: (err: any) => {
          this.logSrv.Error('External login error', err);
          this.loginError = true;
        }
      });

      this.actionSrv.changeLoading();
    }
  }

  passForgotten() {
    const config = {
      placeHolder: $localize`:@@login-front.login-external.email-placeholder: Introduzca su email`,
      label: $localize`:@@login-front.login-external.email-label: Email`,
      inputType: 'email',
      disableClose: true,
      allowCancel: true,
      okActionText: $localize`:@@front-login.login-external.email-action: Aceptar`,
      widthPercent: 25
    } as IHowdenAskForData;

    this.askForDataSrv.open(config).pipe(
      tap({
        next: (value) => {
          if (value.resultData) {
            const title = $localize`:@@front-login.login-external.email-title-info: Información`;
            const message = $localize`:@@front-login.login-external.email-new-password: En el caso de existir un usuario con su email recibirá un correo para solicitar una nueva contraseña`;

            this.apiSecuritySrv.ForgotPassword(value.resultData, this.title, this.callback).subscribe({
              complete: () => this.alertSrv.info(title, message, true)
            });
          }
        }
      })
    ).subscribe();
  }

  private navigate() {
    this.router.navigateByUrl('preferences');
  }

  private get userName(): string {
    return this.formLogin.get(this.C_USERNAME)?.value;
  }

  private get passWord(): string {
    return this.formLogin.get(this.C_PASSWORD)?.value;
  }
}

import { AbstractControl } from '@angular/forms';

// https://angular.io/api/forms/FormGroup#create-a-form-group-with-a-group-level-validator
export function passwordMatchValidator(g: AbstractControl) {
  const password = g.get('password_new')?.value;
  const confirmPassword = g.get('password_repeat')?.value;

  if (!password || !confirmPassword) {
    return null;
  }

  return password === confirmPassword ? null : { 'mismatch': true };
}

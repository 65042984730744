import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errorMsg = '';

  constructor(private route: Router) {
  }

  ShowError(msg: string) {
    this.SetErrorMsg(msg);
    this.route.navigateByUrl('/err');
  }

  SetErrorMsg(msg: string) {
    this.errorMsg = msg;
  }

  get ErrorMsg(): string {
    return this.errorMsg;
  }
}

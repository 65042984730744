<div class="main-external flex f-fd--column-md f-gap--25px f-jc--center f-ai--center">
  <div class="login-panel mat-elevation-z24">
    <img src="assets/images/logo-howden.png" class="howden-logo" alt="Howden Logo" />

    <form [formGroup]="formLogin" (ngSubmit)="login()">
      <h2 class="login-title">{{ title }}</h2>

      <div class="field">
        <label class="label" i18n="@@front-login.login-external.username">Nombre de usuario</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_USERNAME"
            i18n-placeholder="@@front-login.login-external.username-placeholder"
            placeholder="Introduzca su nombre de usuario"
          />
          @if (formLogin.get(C_USERNAME)?.invalid) {
            <mat-error i18n="@@front-login.login-external.username-required">
              El campo nombre de usuario es obligatorio
            </mat-error>
          }
        </mat-form-field>
      </div>

      <div class="field">
        <label class="label" i18n="@@front-login.login-external.password">Contraseña</label>
        <mat-form-field>
          <input
            matInput
            [formControlName]="C_PASSWORD"
            i18n-placeholder="@@front-login.login-external.password-placeholder"
            placeholder="Introduzca su contraseña"
            type="password"
          />
          @if (formLogin.get(C_PASSWORD)?.invalid) {
            <mat-error i18n="@@front-login.login-external.password-required">
              El campo contraseña es obligatorio
            </mat-error>
          }
        </mat-form-field>
      </div>

      @if (loginError) {
        <mat-error i18n="@@front-login.login-external.login-error">
          Usuario y/o contraseña inválidos
        </mat-error>
      }

      <div>
        <button mat-raised-button color="primary" i18n="@@front-login.login-external.enter" [disabled]="formLogin.invalid">
          Entrar
        </button>
      </div>

      <div class="link-container">
        <a class="cursor-pointer" (click)="passForgotten()" i18n="@@front-login.login-external.password-forgotten">
          He olvidado mi contraseña
        </a>
      </div>
    </form>
  </div>
</div>

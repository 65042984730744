import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ActionsService, UserService } from '@app/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'howden-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent implements OnInit, OnDestroy {
  callbackUrl = '';
  appName = '';

  private _subscription?: Subscription;

  constructor(
    private usrSrv: UserService,
    private actionSrv: ActionsService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.actionSrv.changeLoading();
    this.usrSrv.logout();
    this.readParams();
    this.actionSrv.hideLoading();
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  get seeUrl(): boolean {
    return this.callbackUrl !== '';
  }

  private readParams() {
    this._subscription = this.activatedRoute.queryParamMap.subscribe((data: ParamMap) => {
      const url = this.readCallBack(data);
      if (url !== undefined) {
        this.callbackUrl = url;
      }
    });
  }

  private readCallBack(params: ParamMap): string | undefined {
    if (!params.has('callback')) {
      return '';
    }

    const loginParams: string[] = ['callback'];

    let url = params.get('callback')?.toString();

    if (params.has('app')) {
      this.appName = params.get('app') as string;
      loginParams.push('app');
    }

    // try to get another params
    const anotherParams: string[] = [];

    params.keys.forEach((key: string) => {
      if (!loginParams.find(x => x === key)) {
        anotherParams.push(`&${key}=${params.get(key)}`);
      }
    });

    if (anotherParams.length > 0) {
      anotherParams.forEach(param => {
        url = `${url}${param}`;
      });
    }

    return url;
  }
}

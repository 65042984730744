import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services';
import {
  HowdenAskForSelectService,
  HowdenAuthService,
  HowdenSecurityService,
  IAskForSelectData,
  ICompanyAccessDto,
  IHowdenItem,
  IUserPreferencesDto
} from '@howdeniberia/core-front';
import { Observable } from 'rxjs';

@Component({
  selector: 'howden-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit {
  companies?: ICompanyAccessDto[] | null;

  constructor(
    private router: Router,
    private userSrv: UserService,
    private howdenSecuritySrv: HowdenSecurityService,
    private askSrv: HowdenAskForSelectService,
    private authSrv: HowdenAuthService
  ) {
  }

  ngOnInit(): void {
    this.validateUser();
    this.chechDataSourceConfig();
  }

  private validateUser(): void {
    this.companies = this.userSrv.authData.accessCompanies;
    if (!this.companies || this.companies.length === 0) {
      throw new Error('Invalid user configuration, no companies configured');
    }
  }

  private chechDataSourceConfig(): void {
    // If several datasources and not preferred; dialog
    if (!this.companies) {
      return;
    }
    if (this.userSrv.shouldSaveDataSource()) {
      const userPreferences = this.userSrv.authData.userPreferences as IUserPreferencesDto;

      if (this.userSrv.shouldAskWhichDataSource()) {
        this.askDataSource(this.companies).subscribe((result) => {
          userPreferences.actualDataSource = result.key as string;
        });
      } else {
        userPreferences.actualDataSource = this.companies[0]?.companyDataSource;
      }
      this.saveUserPreferences(userPreferences);
    } else {
      this.router.navigateByUrl('result');
    }
  }

  private askDataSource(datasources: ICompanyAccessDto[]): Observable<IHowdenItem> {
    return new Observable(observer => {
      const items: IHowdenItem[] = [];

      datasources.forEach(ds => {
        items.push({
          description: ds.companyName,
          key: ds.companyDataSource,
          selected: false
        } as IHowdenItem);
      });

      const options: IAskForSelectData = {
        initialValue: null,
        items: items,
        label: $localize`:@@front-login.user-preferences.askDataSources.label: Selecciona Empresa`,
        title: $localize`:@@front-login.user-preferences.askDataSources.title: Seleccione Compañía`,
        subTitle: $localize`:@@front-login.user-preferences.askDataSources.subtitle: podrá cambiarla desde su aplicación`,
        disableClose: true,
        allowCancel: false,
        icon: ''
      };

      this.askSrv.open(options).subscribe((result) => {
        observer.next(result.resultData);
        observer.complete();
      });
    });
  }

  private saveUserPreferences(userPreferences: IUserPreferencesDto): void {
    this.authSrv.reload();
    this.howdenSecuritySrv.UpdateUserPreferences(userPreferences).subscribe(() => {
      this.userSrv.saveUserPreferences(userPreferences);
      this.router.navigateByUrl('result');
    });
  }
}

import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import packageJson from 'package.json';

@Injectable({
  providedIn: 'root'
})
export class HowdenInformation {
  private version: string = packageJson.version;
  private appTitle: string = packageJson.name;

  get Version(): string {
    return `${this.version}`;
  }

  get Name(): string {
    return `${this.appTitle}`;
  }

  get NameNormalized(): string {
    const norm = this.appTitle.replace(/\s/g, '');
    return `${norm}`;
  }

  get TitleVersion(): string {
    return `${this.Name} ${this.Version}`;
  }

  get Title(): string {
    return `${this.Name}`;
  }

  get Enviroment(): string {
    return environment.zone.toString();
  }
}
